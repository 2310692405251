import React from 'react';
import { withPrefix } from 'gatsby';

import * as Sections from '../ui-components/home-sections';
import * as Grid from '../ui-components/grid';

const CallToActionSection = () => (
  <Sections.CallToActionWrapper>
    <Grid.Container>
      <Sections.CallToActionRow>
        <Sections.CallToActionColumn>
          <Sections.CallToActionDetails>
            <Sections.CallToActionTitle>
              Sign up today as a beta tester and get a free virtual stack.
            </Sections.CallToActionTitle>

            <Sections.CallToActionDescription>
              <p>Free stack for the first year!</p>
              <p>Discounted hosting planned after a year.</p>
            </Sections.CallToActionDescription>

            <Sections.CallToActionButton to="/signup">
              Try it<span>&#8250;</span>
            </Sections.CallToActionButton>
          </Sections.CallToActionDetails>

          <Sections.CallToActionImage>
            <svg width="582" height="380">
              <use xlinkHref={withPrefix('sprites.svg#cta')} />
            </svg>
          </Sections.CallToActionImage>
        </Sections.CallToActionColumn>
      </Sections.CallToActionRow>
    </Grid.Container>
  </Sections.CallToActionWrapper>
);

export default CallToActionSection;
