import React from 'react';

import * as Sections from '../ui-components/home-sections';
import * as Grid from '../ui-components/grid';

import Features from '../../content/assets/features.png';
import Features2x from '../../content/assets/features@2x.png';
import Features3x from '../../content/assets/features@3x.png';

const FeaturesSection = () => (
  <Sections.FeaturesWrapper>
    <Grid.Container>
      <Sections.FeaturesHeader>
        <Sections.FeaturesTitle>
          The simplest way to create a full-stack React app.
        </Sections.FeaturesTitle>
        <Sections.FeaturesSubtitle>
          <p>No need to manage a server.</p>
          <p>Create a virtual stack with us and start coding.</p>
        </Sections.FeaturesSubtitle>
      </Sections.FeaturesHeader>

      <Sections.FeaturesContent>
        <Sections.FeaturesBlock>
          <Sections.FeaturesBlockTitle>
            Simplest Full Stack Solution
          </Sections.FeaturesBlockTitle>

          <Sections.FeaturesBlockList>
            <Sections.FeaturesBlockListItem>
              Templates available
            </Sections.FeaturesBlockListItem>
            <Sections.FeaturesBlockListItem>
              Simple graphical interface to manage the back end, within your
              app!
            </Sections.FeaturesBlockListItem>
            <Sections.FeaturesBlockListItem>CLI</Sections.FeaturesBlockListItem>
          </Sections.FeaturesBlockList>
        </Sections.FeaturesBlock>

        <Sections.FeaturesImageBlock>
          <Sections.FeaturesImage
            src={Features}
            srcSet={`${Features2x} 2x, ${Features3x} 3x`}
          />
        </Sections.FeaturesImageBlock>

        <Sections.FeaturesBlock>
          <Sections.FeaturesBlockTitle>
            Up-to-date React patterns:
          </Sections.FeaturesBlockTitle>

          <Sections.FeaturesBlockList>
            <Sections.FeaturesBlockListItem>
              Higher Order Components
            </Sections.FeaturesBlockListItem>
            <Sections.FeaturesBlockListItem>
              New React Context API
            </Sections.FeaturesBlockListItem>
            <Sections.FeaturesBlockListItem>
              Function as a Child (Render Props)
            </Sections.FeaturesBlockListItem>
          </Sections.FeaturesBlockList>
        </Sections.FeaturesBlock>
      </Sections.FeaturesContent>
    </Grid.Container>
  </Sections.FeaturesWrapper>
);

export default FeaturesSection;
