import React from 'react';
import { withPrefix } from 'gatsby';

import * as Sections from '../ui-components/home-sections';
import * as Grid from '../ui-components/grid';

const AttributesSection = () => (
  <Sections.AttributesWrapper>
    <Grid.Container>
      <Grid.Row>
        <Grid.Col4>
          <Sections.AttributesBlock>
            <Sections.AttributeIcon>
              <svg width="82.9" height="94">
                <use xlinkHref={withPrefix('sprites.svg#iconNoAuth')} />
              </svg>
            </Sections.AttributeIcon>

            <Sections.AttributeDetails>
              <Sections.AttributeTitle>
                <span>No</span> dealing with auth
              </Sections.AttributeTitle>
              <Sections.AttributeDescription>
                Create unlimited types of users and actions for them
              </Sections.AttributeDescription>
            </Sections.AttributeDetails>
          </Sections.AttributesBlock>
        </Grid.Col4>

        <Grid.Col4>
          <Sections.AttributesBlock>
            <Sections.AttributeIcon>
              <svg width="84.1" height="87">
                <use xlinkHref={withPrefix('sprites.svg#iconNoDB')} />
              </svg>
            </Sections.AttributeIcon>

            <Sections.AttributeDetails>
              <Sections.AttributeTitle>
                <span>No</span> database knowledge required
              </Sections.AttributeTitle>
              <Sections.AttributeDescription>
                Our interface makes it simple
              </Sections.AttributeDescription>
            </Sections.AttributeDetails>
          </Sections.AttributesBlock>
        </Grid.Col4>

        <Grid.Col4>
          <Sections.AttributesBlock>
            <Sections.AttributeIcon>
              <svg width="95.2" height="84">
                <use xlinkHref={withPrefix('sprites.svg#iconNoBE')} />
              </svg>
            </Sections.AttributeIcon>

            <Sections.AttributeDetails>
              <Sections.AttributeTitle>
                <span>No</span> backend code
              </Sections.AttributeTitle>
              <Sections.AttributeDescription>
                Most standard business logic generated
              </Sections.AttributeDescription>
            </Sections.AttributeDetails>
          </Sections.AttributesBlock>
        </Grid.Col4>
      </Grid.Row>
    </Grid.Container>
  </Sections.AttributesWrapper>
);

export default AttributesSection;
