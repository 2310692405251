import React from 'react';
import * as Sections from '../ui-components/home-sections';
import * as Grid from '../ui-components/grid';

import schemeImage from '../../content/assets/scheme.png';
import schemeImage2x from '../../content/assets/scheme@2x.png';
import schemeImage3x from '../../content/assets/scheme@3x.png';

const SchemeSection = () => (
  <Sections.SchemeWrapper>
    <Grid.Container>
      <Sections.SchemeTitle>
        Build Complete Full Stack Apps <span>Without the stack</span>
      </Sections.SchemeTitle>

      <Sections.SchemeBlock>
        <Sections.SchemeImage
          src={schemeImage}
          srcSet={`${schemeImage2x} 2x, ${schemeImage3x} 3x`}
          alt="Build Complete FullStack Apps Without the Stack"
        />
      </Sections.SchemeBlock>

      <Sections.SchemeBlockMobile>
        <svg width="292" height="610.836">
          <use xlinkHref="sprites.svg#schemeMobile" />
        </svg>
      </Sections.SchemeBlockMobile>
    </Grid.Container>
  </Sections.SchemeWrapper>
);

export default SchemeSection;
