import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

import HeroSection from '../components/HeroSection';
import SchemeSection from '../components/SchemeSection';
import FeaturesSection from '../components/FeaturesSection';
import CallToActionSection from '../components/CallToActionSection';
import AttributesSection from '../components/AttributesSection';

const SiteIndex = () => {
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[`no-stack`, `gatsby`, `javascript`, `react`]}
      />

      <HeroSection />
      <SchemeSection />
      <FeaturesSection />
      <CallToActionSection />
      <AttributesSection />
    </Layout>
  );
};

export default SiteIndex;
