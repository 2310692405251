import React from 'react';

import * as Sections from '../ui-components/home-sections';
import * as Grid from '../ui-components/grid';

import heroImage from '../../content/assets/intro.png';

const HeroSection = () => (
  <Sections.HeroWrapper>
    <Grid.Container>
      <Grid.Row>
        <Sections.HeroSloganContainer>
          <Sections.HeroSlogan>
            <Sections.HeroTitle>
              All the App. <span>NoStack.</span>
            </Sections.HeroTitle>

            <Sections.HeroDescription>
              The React package that turns you into a full stack developer
            </Sections.HeroDescription>
          </Sections.HeroSlogan>

          <Sections.HeroCTAButton to="/signup">
            Get Started!
          </Sections.HeroCTAButton>
        </Sections.HeroSloganContainer>

        <Sections.HeroImageContainer>
          <Sections.HeroImage src={heroImage} alt="" />
        </Sections.HeroImageContainer>
      </Grid.Row>
    </Grid.Container>
  </Sections.HeroWrapper>
);

export default HeroSection;
