import styled from 'styled-components';
import { Link } from 'gatsby';

import variables from './variables';
import { unselectable } from './mixins';

export const PrimaryButton = styled(Link)`
  color: ${variables.colors.white};
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-decoration: none;

  display: inline-block;

  padding: 23px 47px;

  border-radius: 4px;
  border: solid 1px ${variables.colors.green};
  background: ${variables.buttonBG};

  box-shadow: 0px 4px 0px 0px #138502;

  &:hover {
    background: ${variables.buttonBGHover};
    box-shadow: 0px 4px 0px 0px #138502;
  }

  &:disabled,
  &[disabled] {
    background: #ccc;
    border: 1px solid #aaa;
    box-shadow: 0px 4px 0px 0px #aaa;

    &:hover {
      background: #ccc;
    }
  }

  ${unselectable}

  @media only screen and (max-width: 624px) {
    font-size: 20px;
    line-height: 27px;
    padding: 14px 40px;
    box-shadow: 0px 2px 0px 0px #138502;
  }
`;

export const InvertedButton = styled(PrimaryButton)`
  color: ${variables.colors.green};
  background: ${variables.buttonBGInvert};

  &:hover {
    background: ${variables.buttonBGInvertHover};
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  color: ${variables.colors.black};
  background: ${variables.colors.white};

  &:hover {
    color: ${variables.colors.white};
  }
`;
